//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

.link-default {
  color: $color-dark-green !important;
  padding: 0 !important;
  text-decoration: underline;
}

.link-default:hover {
  color: $color-dark-green !important;
}

.link-success {
  color: $color-default-2 !important;
  padding: 0 !important;
  text-decoration: underline;
}

.link-success:hover {
  color: $color-default-2 !important;
}

.color-green {
  color: $color-default-2;
}

.spinner-loading {
  position: absolute;
  top: 49%;
  left: 50%;
}

.ft-size-1 {
  font-size: 1.25em;
}

.mt-3-larger {
  margin-top: 1.7rem !important;
}

input[type='date' i]::-webkit-calendar-picker-indicator {
  filter: invert(54%) sepia(44%) saturate(394%) hue-rotate(136deg)
    brightness(92%) contrast(89%);
}

.color-pudo-primary {
  background: $color-lemon;
}

.box-time {
  padding: 13px 13px 5px 13px;
  margin: 10px 0 15px 0;
  background: #f6f7fb;
}

@media (min-width: 768px) {
  .time-left {
    padding-left: 5px !important;
  }
  .time-rigth {
    padding-right: 5px !important;
  }
}
